@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

@import url("https://use.typekit.net/ofz8ktj.css");

body {
  font-family: acumin-pro, sans-serif;
}

a {
  color: #2e6cc6;
}

a:hover {
  color: #2e6cc6;
}